<template>
  <div>
    <a-row :gutter="7">
      <a-col :span="21">
        <a-auto-complete
          v-model="searchVal"
          size="large"
          placeholder="Cari Alamat"
          @select="onSelect"
        >
          <template slot="dataSource">
            <a-select-option v-for="item in searchResults" :key="item.place_id">
              {{ item.description }}
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-col>
      <a-col :span="2">
        <a-button icon="environment" size="large" @click="viewMaps()"  />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { message } from "ant-design-vue";

export default {
  name: "AddressFromMapList",
  data() {
    return {
      isSearching: false,
      isLoading: false,
      value: null,
      searchVal: "",
      searchResults: [],
      selectedResult: {},
      currentLocationDetail: "",
      currentLocation: { lat: -6.1924924, lng: 106.8217703 },
      isLocationOutOfRange: false,
    };
  },
  props: {
    isMapsShow: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
    },
  },
  mounted() {
    const checkGMapAPI = () => {
      if (this.$gmapsService) {
        this.$mapsGeocode.geocode({ location: this.currentLocation }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              const routeType = results.find(
                ({ types }) => types.includes("street_address") || types.includes("route"),
              );
              this.currentLocationDetail = routeType || results[0];
              this.selectedResult = routeType || results[0];
            } else {
              message.error(this.$t("ADDRESS.ERROR.ADDRESS_NOT_FOUND"));
            }
          } else {
            message.error(`Geocoder failed due to: ${status}`);
          }
        });
      } else {
        setTimeout(() => checkGMapAPI(), 1000);
      }
    };
    checkGMapAPI();
  },
  methods: {
    onSelect(value) {
      this.currentLocationDetail = value;
      this.setAddress();
    },
    displaySuggestions(predictions, status) {
      if (predictions) {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          this.searchResults = [];
          return;
        }
        this.isSearching = false;
        this.searchResults = predictions;
      }
    },
    setAddress() {
      this.isLoading = true;
      this.$mapsGeocode.geocode(
        { placeId: this.currentLocationDetail },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              this.$emit("handleAddress", {
                location: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                },
                address: results[0].formatted_address,
              });
            } else {
              message.error(this.$t("ADDRESS.ERROR.ADDRESS_NOT_FOUND"));
            }
            this.isLoading = false;
          } else {
            message.error(`Geocoder failed due to: ${status}`);
          }
        },
      );
    },
    getPlacePredictions(input) {
      this.isSearching = true;
      this.$mapsPlaces.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: "ID" },
        },
        this.displaySuggestions,
      );
    },
    viewMaps() {
      this.$emit("update:isMapsShow", true);
    },
  },
  beforeMount() {
    this.currentLocation = this.location;
  },
  watch: {
    searchVal(val) {
      if (val) {
        const timeout = 1000;
        clearTimeout(this.wew);
        this.wew = setTimeout(() => this.getPlacePredictions(val), timeout);
      } else this.searchResults = [];
    },
  },
};
</script>
