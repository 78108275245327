<template>
  <div>
    <div v-if="!allowPage">
      <permission-denied :label="'manage store'"/>
    </div>
    <div v-else>
      <portal to="header-toolbar">
        <span class="header-download-csv">
          <a-icon type="download" style="margin-right: 8px;" />
          Unduh CSV
        </span>
        <button class="header-button-add" @click="openForm">
          <a-icon type="plus" class="icon" />
          Tambah Cabang
        </button>
      </portal>
      <dashboard-table
        searchPlaceholder="Cari Cabang"
        :columnTable="columnsAdmin"
        :dataTable="getStoresComputed"
        :pagination="pagination"
        :loadingTable="loading"
        :onSearchTable="onSearch"
        :perPageValue="perPageValue"
        @handleTableChange="handleTableChange"
        @showFilter="openFilterModal"
        @handlePerPageChange="handlePerPageChange"
      >
        <template slot="statusData" slot-scope="{ record }">
          <a-tag class="prixa-ant-tag" :color="record | statusColor">{{ record }}</a-tag>
        </template>
        <template slot="actionDropdown" slot-scope="{ record }">
          <a-menu-item v-if="record.id !== getUserId">
            <a href="javascript:;" @click="handleUpdate({ id: record.id, step: 0 })">
              Ubah Data
            </a>
          </a-menu-item>
          <template v-if="record.id !== getUserId">
            <a-menu-divider />
            <a-menu-item>
              <a href="javascript:;"
                @click="handleDelete(record)"
                class="color-danger"
              >
                Hapus Cabang
              </a>
            </a-menu-item>
          </template>
        </template>
      </dashboard-table>
      <store-modal
        ref="form"
        :modal-title="modalTitle"
        @fetchData="fetchData"
        @filter="filterData"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Portal } from "portal-vue";
import { mapGetters, mapActions } from "vuex";
import DashboardTable from "@/components/table/DashboardTable.vue";
import StoreModal from "@/components/store/StoreModal.vue";
import PermissionDenied from "@/components/PermissionDenied.vue";

const columnsAdmin = [
  {
    index: 0,
    title: "No",
    dataIndex: "index",
    key: "index",
    width: 50,
  },
  {
    index: 1,
    title: "Nama Cabang",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    index: 2,
    title: "Kota",
    dataIndex: "city",
    key: "city",
    sorter: false,
  },
  {
    index: 3,
    title: "Jam Layanan",
    dataIndex: "time",
    key: "time",
    sorter: false,
  },
  {
    index: 4,
    title: "Status",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "user_status" },
  },
  {
    index: 5,
    title: false,
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 100,
    align: "center",
  },
];

export default Vue.extend({
  data() {
    return {
      allowPage: false,
      isFirst: true,
      columnsAdmin,
      loading: true,
      pagination: {
        page: 1,
        perpage: 5,
        current: 1,
        total: 0,
        pageSize: 0,
      },
      search: "",
      filter: {
        is_active: null,
        cities: [],
      },
      sorter: [{
        column: "UPDATED_AT",
        order: "DESC",
      }],
      modalTitle: "",
      perPageValue: 10,
    };
  },
  components: {
    Portal,
    DashboardTable,
    StoreModal,
    PermissionDenied,
  },
  filters: {
    statusColor(record) {
      let color = "blue";
      if (record === "Aktif") color = "green";
      else if (record === "Tidak Aktif") color = "red";
      return color;
    },
  },
  computed: {
    ...mapGetters("UserStore", ["getUserRole", "hasPermission", "getUserId", "getClientId"]),
    ...mapGetters("CityStore", ["getCities"]),
    ...mapGetters("ManageStoreStore", ["getStores", "getPaginatorInfo", "getUIFlags"]),
    getStoresComputed() {
      const storeData = this.getStores.map((store, index) => {
        const tmp = store;
        const objCity = this.getCities[this.getCities.findIndex((s) => s.id === tmp.city_id)];
        tmp.index = this.pagination.pageSize * (this.pagination.current - 1) + index + 1;
        tmp.city = objCity ? this.titleCase(objCity.name) : "N/A";
        tmp.time = `${tmp.opening_time.substring(0, 5)} - ${tmp.closing_time.substring(0, 5)} WIB`;
        tmp.status = tmp.is_active ? "Aktif" : "Tidak Aktif";
        return tmp;
      });
      return this.isFirst ? [] : storeData;
    },
  },
  methods: {
    ...mapActions("UserStore", [
      "getUserData",
    ]),
    ...mapActions("CityStore", ["fetchCities"]),
    ...mapActions("ManageStoreStore", ["fetchStores"]),
    openForm() {
      this.modalTitle = "Tambah Cabang Apotek";
      (this.$refs.form).showModal({ step: 0 });
    },
    openFilterModal() {
      this.modalTitle = "Filter";
      (this.$refs.form).showFilter();
    },
    onSearch(value) {
      this.search = value;
      this.pagination.page = 1;
      this.fetchData();
    },
    filterData(filter) {
      this.filter = filter;
      this.pagination.page = 1;
      this.fetchData();
    },
    handleUpdate(record) {
      this.modalTitle = "Ubah Data Cabang Apotek";
      (this.$refs.form).showModal(record);
    },
    sendVerification(record) {
      this.modalTitle = "Verifikasi Email";
      (this.$refs.form).showVerification(record);
    },
    handleDelete(record) {
      this.modalTitle = "Hapus Cabang";
      (this.$refs.form).showDelete(record);
    },
    handlePerPageChange(value) {
      this.perPageValue = Number(value);
      const pager = { ...this.pagination };
      pager.perpage = Number(value);
      this.pagination = pager;
      if (pager.perpage > pager.total) {
        this.pagination.page = 1;
      }
      this.fetchData();
    },
    handleTableChange({ pagination, sorter }) {
      if (sorter.order) {
        this.sorter = [{ column: sorter.field.toUpperCase(), order: sorter.order === "ascend" ? "ASC" : "DESC" }];
      } else this.sorter = [{ column: "UPDATED_AT", order: "DESC" }];

      const pager = { ...this.pagination };
      pager.page = pagination.current;
      pager.perpage = pagination.pageSize;
      this.pagination = pager;
      this.pagination.perpage = pagination.pageSize;
      this.pagination.page = pagination.current;
      this.fetchData();
    },
    titleCase(str) {
      const splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i += 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
    fetchData() {
      this.loading = true;
      this.fetchStores({
        search: this.search,
        first: this.perPageValue,
        page: this.pagination.page,
        orderBy: this.sorter,
        is_active: this.filter.is_active,
        cities: this.filter.cities,
      })
        .then((data) => {
          const tmpPagination = { ...this.pagination };
          tmpPagination.total = data.paginatorInfo.total;
          tmpPagination.pageSize = Number(data.paginatorInfo.perPage);
          tmpPagination.current = Number(data.paginatorInfo.currentPage);
          this.pagination = tmpPagination;
          this.isFirst = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log("error: ", error);
        });
    },
  },
  created() {
    this.$emit("setRightSidebarVisible", false);
    this.allowPage = (this.getUserRole === "externalUsers-pharmacies-admin");
  },
  mounted() {
    this.getUserData();
    this.fetchCities();
    this.fetchData();
  },
});
</script>

<style lang="scss" scoped>
.header-download-csv {
  margin-right: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #55b9e4;
  cursor: pointer;
}

.header-button-add {
  padding: 14px 24px;
  border-radius: 26px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #0146ab;
  outline: none;

  .icon {
    font-size: 16px;
  }

  &:focus {
    background: #1f64b8;
  }

  &:active {
    background: #0146ab;
  }
}

.prixa-ant-tag {
  padding: 2px 16px;
  border-radius: 16px;
}
</style>
