<template>
  <div>
    <h1>{{ `You don't have permission to access ${label}` }}.</h1>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    label: {
      type: String,
    },
  },
});
</script>
