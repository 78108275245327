<template>
  <a-spin :spinning="isLoading">
    <a-row :gutter="48">
      <a-col :span="10">
        <div class="store-steps-container">
          <a-steps v-model="current" direction="vertical" size="small">
            <a-step
              title="Informasi Cabang"
              description="Lengkapi informasi cabang apotek yang akan ditambahkan"
              :disabled="(id) ? false : (current > 0) ? false : true"
            />
            <a-step
              title="Lokasi Cabang"
              description="Lengkapi informasi lokasi cabang apotek yang akan ditambahkan"
              :disabled="(id && nextStatus(0)) ? false : nextStatus(0)
                ? false : true"
            />
            <a-step
              title="Admin dan Apoteker"
              description="Tentukan pengguna yang akan diberikan akses kelola untuk cabang apotek"
              :disabled="(id && nextStatus(1)) ? false : nextStatus(1)
                ? false : true"
            />
          </a-steps>
        </div>
      </a-col>
      <a-col :span="14">
        <a-form
          :layout="formLayout"
          :form="form"
          @submit="handleSubmit"
          :hideRequiredMark="true"
        >
          <div class="margin-baseB" v-if="isSubmitError">
            <a-alert type="error" :message="submitErrorMessage" banner />
          </div>
            <div class="steps-content">
              <div v-show="current === 0">
                <a-form-item
                  label="Nama Cabang Apotek"
                  :validate-status="checkError('name') ? 'error' : ''"
                  :help="checkError('name') || ''"
                >
                  <a-input
                    size="large"
                    placeholder="Misal : Apotek Sehat"
                    v-decorator="[
                      'name',
                      { rules: [{ required: true, message: 'Data tidak valid' }] },
                    ]"
                  />
                </a-form-item>
                <a-form-item
                  label="No. Telepon"
                  :validate-status="checkError('phone') ? 'error' : ''"
                  :help="checkError('phone') || ''"
                >
                  <a-input
                    size="large"
                    placeholder="Misal : 0821xxxx & +628xxxxx"
                    v-decorator="[
                      'phone',
                      {
                        rules: [{
                          required: true, message: 'Data tidak valid',
                          pattern: /^(^\+62\s?|^0)(\d{3,4}){2}\d{3,3}$/g,
                        }],
                      },
                    ]"
                  />
                </a-form-item>
                <a-row :gutter="80">
                  <a-col :span="8">
                    <a-form-item
                      label="Jam Buka"
                      :validate-status="checkError('openClock') ? 'error' : ''"
                      :help="checkError('openClock') || ''"
                    >
                      <a-time-picker
                        size="large" use24-hours format="HH:mm"
                        placeholder="Misal 08:00"
                        v-decorator="[
                          'openClock',
                          { rules: [{ required: true, message: 'Data tidak valid' }] },
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item
                      label="Jam Tutup"
                      :validate-status="checkError('closeClock') ? 'error' : ''"
                      :help="checkError('closeClock') || ''"
                    >
                      <a-time-picker
                        size="large" use24-hours format="HH:mm"
                        placeholder="Misal 21:00"
                        v-decorator="[
                          'closeClock',
                          { rules: [{ required: true, message: 'Data tidak valid' }] },
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-form-item
                  :validate-status="checkError('isActive') ? 'error' : ''"
                  :help="checkError('isActive') || ''"
                >
                  <template slot="label">
                    <div>
                      Status
                    </div>
                  </template>
                  <a-select
                    size="large"
                    show-search
                    placeholder="Pilih Status"
                    option-filter-prop="children"
                    style="width: 100%"
                    @change="handleChange"
                    v-decorator="[
                      'isActive',
                      { rules: [{ required: true }] },
                    ]"
                  >
                    <a-select-option value="true">
                      Aktif
                    </a-select-option>
                    <a-select-option value="false">
                      Tidak Aktif
                    </a-select-option>
                  </a-select>
                  <h5 :style="{ color: '#b2b3b5', marginTop: '5px' }">{{ remarksStatus }}</h5>
                </a-form-item>
              </div>
              <div v-show="current === 1">
                <div v-show="isMapsShow">
                  <a-modal
                    :mask="false"
                    :width="864"
                    :bodyStyle="{ maxHeight: '750px', padding: '0px', overflow: 'hidden'}"
                    :closable="false"
                    :footer="null"
                    :visible="isMapsShow"
                    centered
                  >
                    <div :style="{ maxHeight: '850px' }" />
                    <address-maps v-if="isMapsShow"
                      :isMapsShow.sync="isMapsShow"
                      :location="location"
                      @handleAddress="handleAddress"
                    />
                  </a-modal>
                </div>
                <a-form-item>
                  <template slot="label">
                    <div>
                      Koordinat Lokasi
                    </div>
                  </template>
                  <address-map-list
                    :isMapsShow.sync="isMapsShow"
                    :location="location"
                    @handleAddress="handleAddress"
                  />
                </a-form-item>
                <a-form-item
                  :validate-status="checkError('city') ? 'error' : ''"
                  :help="checkError('city') || ''"
                >
                  <template slot="label">
                    <div>
                      Kota
                    </div>
                  </template>
                  <a-select
                    size="large"
                    show-search
                    placeholder="Misal : Jakarta Pusat"
                    option-filter-prop="children"
                    style="width: 100%"
                    @change="handleChange"
                    v-decorator="[
                      'city',
                      { rules: [{ required: true }] },
                    ]"
                  >
                    <a-select-option v-for="city in getCities" :key="city.id" :value="city.id">
                      {{ city.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item
                  label="Alamat"
                  :validate-status="checkError('address') ? 'error' : ''"
                  :help="checkError('address') || ''"
                >
                  <a-input
                    :disabled="true"
                    size="large"
                    placeholder="Alamat"
                    type="textarea"
                    rows="4"
                    v-decorator="[
                      'address',
                      { rules: [{ required: true, message: 'Data tidak valid' }] },
                    ]"
                  />
                </a-form-item>
                <a-form-item
                  label="Kode Pos"
                  :validate-status="checkError('zipcode') ? 'error' : ''"
                  :help="checkError('zipcode') || ''"
                >
                  <a-input
                    size="large"
                    placeholder="Misal : 10350"
                    :maxLength="5"
                    v-decorator="[
                      'zipcode',
                      {
                        rules: [{
                          required: true, message: 'Data tidak valid',
                          pattern: /^\d{5}/,
                        }],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div v-show="current === 2">
                <a-form-item
                  :validate-status="checkError('users') ? 'error' : ''"
                  :help="checkError('users') || ''"
                >
                  <template slot="label">
                    <div>
                      Tambah Admin dan Apoteker
                    </div>
                  </template>
                  <a-select
                    mode="multiple"
                    size="large"
                    show-search
                    placeholder="Cari Admin & Apoteker"
                    option-filter-prop="children"
                    style="width: 100%"
                    @change="handleChange"
                    v-decorator="[
                      'users',
                      { rules: [{ required: true, message: 'Data tidak valid' }] },
                    ]"
                  >
                    <a-icon slot="prefix" type="search" />
                    <a-select-opt-group label="Admin Farmasi">
                      <a-select-option v-for="admin in getStoreListMembers.admins"
                        :key="admin.id" :value="admin.id"
                      >
                        {{ admin.name }}
                      </a-select-option>
                    </a-select-opt-group>
                    <a-select-opt-group label="Apoteker">
                      <a-select-option v-for="pharmacy in getStoreListMembers.pharmacists"
                        :key="pharmacy.id" :value="pharmacy.id"
                      >
                        {{ pharmacy.name }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                  <h5 :style="{ color: '#b2b3b5', marginTop: '5px' }">{{ remarksUsers }}</h5>
                </a-form-item>
              </div>
            </div>
          <a-form-item>
            <div class="p-modal-button-container">
              <a-button
                class="p-modal-cancel"
                size="large"
                @click="prev(current)"
              >
                {{ (current === 0) ? "Batal" : "Kembali" }}
              </a-button>
              <a-button
                v-show="current < 2"
                :disabled="!nextStatus(this.current)"
                type="primary"
                size="large"
                @click="next"
                class="p-modal-submit"
              >
                Selanjutnya
              </a-button>
              <a-button
                v-show="current === 2"
                type="primary"
                size="large"
                html-type="submit"
                :disabled="hasErrors(form.getFieldsError())"
                :loading="loading"
                class="p-modal-submit"
              >
                {{ this.id ? "Ubah Data" : "Tambah Cabang" }}
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import { message } from "ant-design-vue";
import moment from "moment";
import AddressMaps from "@/components/store/address/AddressMaps.vue";
import AddressMapList from "@/components/store/address/AddressMapList.vue";

export default Vue.extend({
  data() {
    return {
      formLayout: "vertical",
      clientIdUser: "",
      isLoading: false,
      isSubmitError: false,
      submitErrorMessage: "Gagal menambah admin, mohon ulangi",
      current: 0,
      isMapsShow: false,
      location: { lat: -6.175392, lng: 106.827153 },
      remarksStatus: "Hanya cabang apotek dengan status Aktif yang dapat menerima permintaan pesan antar farmasi.",
      remarksUsers: "Ketik nama admin dan apoteker yang akan ditambahkan lalu pilih dari hasil pencarian. Hanya admin dan apoteker yang ditambahkan yang dapat menangani permintaan antar farmasi yang masuk.",
    };
  },
  props: {
    id: {
      type: String,
    },
    recordStep: {
      type: Number,
      default: 0,
    },
    clientId: {
      type: String,
    },
  },
  components: {
    AddressMapList,
    AddressMaps,
  },
  computed: {
    ...mapGetters("FormStore", {
      hasErrors: "getErrors",
      form: "getForm",
      loading: "getLoading",
    }),
    ...mapGetters("UserStore", ["getUserRole", "hasPermission", "getUserId", "getClientId"]),
    ...mapGetters("CityStore", ["getCities"]),
    ...mapGetters("StoreMembersStore", ["getStoreListMembers"]),
    ...mapGetters("ManageStoreStore", ["getStore"]),
  },
  methods: {
    moment,
    ...mapMutations("FormStore", {
      setForm: "SET_FORM",
      validateFirst: "VALIDATE_FIRST",
      setLoading: "SET_LOADING",
    }),
    ...mapActions("StoreMembersStore", ["fetchExternalUserByClientIdAll", "saveStoreMembers"]),
    ...mapActions("ManageStoreStore", ["createStore", "fetchStore", "updateStore"]),
    next() {
      this.current += 1;
    },
    prev(current) {
      if (current === 0) {
        this.handleCancel();
      } else {
        this.current -= 1;
      }
    },
    nextStatus(page) {
      switch (page) {
        case 0:
          if (!this.form.getFieldsError().name
            && !this.form.getFieldsError().phone
            && !this.form.getFieldsError().openClock
            && !this.form.getFieldsError().closeClock
            && !this.form.getFieldsError().isActive) return true;
          return false;
        case 1:
          if (!this.form.getFieldsError().name
            && !this.form.getFieldsError().phone
            && !this.form.getFieldsError().openClock
            && !this.form.getFieldsError().closeClock
            && !this.form.getFieldsError().isActive
            && !this.form.getFieldsError().city
            && !this.form.getFieldsError().zipcode
            && !this.form.getFieldsError().address) return true;
          return false;
        case 2:
          if (!this.form.getFieldsError().name
            && !this.form.getFieldsError().phone
            && !this.form.getFieldsError().openClock
            && !this.form.getFieldsError().closeClock
            && !this.form.getFieldsError().isActive
            && !this.form.getFieldsError().city
            && !this.form.getFieldsError().zipcode
            && !this.form.getFieldsError().address
            && !this.form.getFieldsError().users) return true;
          return false;
        default:
          return false;
      }
    },
    checkError(field) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(field) && getFieldError(field);
    },
    viewRolesInformation() {
      this.$emit("view-roles-information");
    },
    setRoleIndoName(role) {
      if (role === "externalUsers-pharmacies-admin") return "Admin";
      return "";
    },
    showDrawer() {
      this.isMapsShow = true;
    },
    handleChange() {
    },
    handlePanelChange(open) {
      if (open) this.mode = ["time", "time"];
    },
    handleAddress(data) {
      this.location = data.location;
      const cityName = data.address.split(", ").reverse();
      const objCity = this.getCities[
        this.getCities.findIndex((s) => s.name === cityName[2].toUpperCase())
      ];
      const zipCode = cityName[1].substring(cityName[1].length - 5, cityName[1].length);
      this.form.setFieldsValue({
        city: (objCity) ? objCity.id : "7e5c94f0-ed40-11ea-9514-6d773faf284b", // default city_id JKT
        zipcode: !Number.isNaN(Number(zipCode)) ? zipCode : undefined,
        address: data.address,
      });
      this.form.validateFields(["zipcode"], { force: true });
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.isSubmitError = false;
      this.form.validateFields((err, values) => {
        this.setLoading(true);
        const storeData = {
          name: values.name,
          contact_phone: values.phone,
          opening_time: moment(values.openClock).format("HH:mm:ss"),
          closing_time: moment(values.closeClock).format("HH:mm:ss"),
          is_active: (values.isActive === "true"),
          city_id: values.city,
          latitude: this.location.lat,
          longitude: this.location.lng,
          zipcode: values.zipcode,
          address: values.address,
        };
        if (this.id) {
          this.updateStore({ id: this.id, input: storeData, members: values.users })
            .then(() => {
              this.setLoading(false);
              message.success(`Cabang apotek ${values.name} berhasil diubah`);
              this.$emit("handleOk");
            }).catch((error) => {
              console.log(error);
              this.setLoading(false);
              this.submitErrorMessage = "Gagal mengubah cabang, mohon ulangi";
              this.isSubmitError = true;
            });
        } else {
          const createStore = this.createStore(storeData).then();
          const saveStoreMembers = createStore.then((res) => this.saveStoreMembers({
            store_id: res.data.createStore.id,
            members: values.users,
          }));
          Promise.all([createStore, saveStoreMembers]).then(() => {
            this.setLoading(false);
            message.success(`Cabang apotek ${values.name} berhasil ditambah`);
            this.$emit("handleOk");
          }).catch((error) => {
            console.log(error);
            this.setLoading(false);
            this.submitErrorMessage = "Gagal menambah cabang, mohon ulangi";
            this.isSubmitError = true;
          });
        }
      });
    },
  },
  created() {
    // const userData = JSON.parse(String(localStorage.getItem("userData")));
    if (this.recordStep) this.current = this.recordStep;
    this.fetchExternalUserByClientIdAll({ clientId: this.getClientId, page: 1, perpage: 500 });
    this.setForm(this.$form.createForm(this, { name: "store_form" }));
  },
  mounted() {
    if (this.id) {
      this.isLoading = true;
      const data = {
        id: this.id,
      };
      this.fetchStore(data)
        .then(() => {
          this.form.setFieldsValue({
            name: this.getStore.store.name,
            phone: this.getStore.store.contact_phone,
            openClock: moment(this.getStore.store.opening_time, "HH:mm"),
            closeClock: moment(this.getStore.store.closing_time, "HH:mm"),
            city: this.getStore.store.city_id,
            zipcode: this.getStore.store.zipcode,
            address: this.getStore.store.address,
            isActive: String(this.getStore.store.is_active),
            users: this.getStore.storeMembers.map((item) => item.user_id),
          });
          this.location = {
            lat: Number(this.getStore.store.latitude),
            lng: Number(this.getStore.store.longitude),
          };
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$emit("handleCancel");
          message.error("Terjadi masalah coba beberapa saat lagi");
        });
    } else {
      this.location = JSON.parse(sessionStorage.getItem("coords"));
      this.form.setFieldsValue({
        isActive: String(true),
      });
    }
    this.validateFirst(this);
  },
});
</script>

<style lang="scss">
.store-steps-container {
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;

  .ant-steps-item-title {
    font-size: 18px;
    font-weight: bold;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background: #0146ab;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    color: #fff;
  }

  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
  }

  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process).ant-steps-item-wait
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon .ant-steps-icon {
    color: #0148ab;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #0146ab;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #0146ab;
  }

}
</style>
