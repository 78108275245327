<template>
  <div class="maps-container">
    <div id="map" class="maps"></div>
    <div class="back-button" @click="backToAddressList()"><a-icon type="left" /></div>
    <div class="address-container">
      <div class="address-card">
        <template v-if="isLoading">
          <a-skeleton active />
        </template>
        <template v-else>
          <a-row>
            <a-col :span="2">
              <font-awesome-icon icon="map-marker-alt" />
            </a-col>
            <a-col :span="20">
              <span class="subtitle">{{ currentLocationFormattedAddress }}</span>
            </a-col>
            <a-col :span="2">
              <a-button @click="updateGeometryLocation()" shape="circle">
                <font-awesome-icon icon="crosshairs" />
              </a-button>
            </a-col>
          </a-row>
          <a-button @click="chooseAddress()" class="btn-option" size="large" type="primary">
            {{ $t("ADDRESS.SELECT") }}
          </a-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";

export default {
  name: "Maps",
  data() {
    return {
      appKey: process.env.VUE_APP_GMAP_KEY,
      currentLocationDetail: "",
      currentLocation: { lat: -6.1924924, lng: 106.8217703 },
      isLoading: true,
    };
  },
  props: {
    isMapsShow: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
    },
  },
  computed: {
    currentLocationFormattedAddress() {
      return this.currentLocationDetail.formatted_address || "";
    },
  },
  methods: {
    backToAddressList() {
      this.$emit("update:isMapsShow", false);
    },
    chooseAddress() {
      this.$emit("handleAddress", {
        location: this.currentLocation,
        address: this.currentLocationDetail.formatted_address,
      });
      this.$emit("update:isMapsShow", false);
    },
    updateGeometryLocation() {
      const showPosition = (position) => {
        const { latitude: lat, longitude: lng } = position.coords;
        sessionStorage.setItem("coords", JSON.stringify({ lat, lng }));
        this.currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.googleMapAPI();
      };

      const showError = (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            message.error("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            message.error("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            message.error("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
            message.error("An unknown error occurred.");
            break;
          default:
            // eslint-disable-next-line
            message.error("An unknown error occurred.");
        }
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
      } else {
        message.error("Geolocation is not supported by this device.");
      }
    },
    googleMapAPI() {
      const checkGMapAPI = () => {
        if (this.$gmapsService) {
          const mapComponent = document.getElementById("map");
          const map = new this.$gmapsService.Map(mapComponent, {
            zoom: 18,
            center: this.currentLocation,
            draggable: true,
            disableDefaultUI: true,
          });
          const marker = new this.$gmapsService.Marker({
            position: this.currentLocation,
            map,
            draggable: false,
          });

          map.addListener("center_changed", () => {
            this.isLoading = true;
            const location = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
            const timeout = 1000;
            clearTimeout(this.debounceMapDrag);
            this.debounceMapDrag = setTimeout(
              () => this.$mapsGeocode.geocode(
                {
                  location,
                },
                (results) => {
                  this.currentLocation = location;
                  [this.currentLocationDetail] = results;
                  this.isLoading = false;
                },
              ),
              timeout,
            );

            marker.setPosition(map.getCenter());
          });

          marker.addListener("dragend", (mapsMouseEvent) => {
            this.isLoading = true;
            this.$mapsGeocode.geocode(
              {
                location: JSON.parse(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)),
              },
              (results) => {
                [this.currentLocationDetail] = results;
                this.isLoading = false;
              },
            );
          });
          this.$mapsGeocode.geocode({ location: this.currentLocation }, (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                const routeType = results.find(
                  ({ types }) => types.includes("street_address") || types.includes("route"),
                );
                this.currentLocationDetail = routeType || results[0];
                this.isLoading = false;
              } else {
                message.error(this.$t("ADDRESS.ERROR.ADDRESS_NOT_FOUND"));
              }
            } else {
              message.error(`Geocoder failed due to: ${status}`);
            }
          });
        } else {
          setTimeout(() => checkGMapAPI(), 1000);
        }
      };
      checkGMapAPI();
    },
  },
  beforeMount() {
    this.currentLocation = this.location;
  },
  mounted() {
    this.googleMapAPI();
  },
};
</script>
