<template>
  <div>
    <a-spin :spinning="isLoading">
      <template slot="title">
        <div>
          <span class="p-modal-header-title">
            Filter
          </span>
        </div>
      </template>
      <a-form :layout="formLayout" @submit="handleSubmit">
        <a-row>
          <a-col :span="24">
            <a-form-item label="Status">
              <a-checkbox
                :style="checkboxStyle"
                :checked="statusAll"
                :indeterminate="statusIndeterminate"
                @change="onCheckedAllOptionsStatus"
                :value="['true', 'false']"
              >
                  Pilih Semua
                </a-checkbox>
              <a-checkbox-group v-model="statusCheckbox" @change="onChangeFilterStatus">
                <a-checkbox :style="checkboxStyle" value="true">
                  Aktif
                </a-checkbox>
                <a-checkbox :style="checkboxStyle" value="false">
                  Tidak Aktif
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
            <a-form-item label="Kota">
              <a-checkbox
                :style="checkboxStyle"
                :checked="citiesAll"
                :indeterminate="citiesIndeterminate"
                @change="onCheckedAllOptionsCities"
                :value="getListStoreCity"
              >
                  Pilih Semua
              </a-checkbox>
              <a-checkbox-group v-model="citiesCheckbox" @change="onChangeFilterCities">
                <a-row :gutter="48">
                  <a-col v-for="item in getListStoreCityComputed" :key="item.id" :span="12">
                    <a-checkbox :value="item.id" :style="checkboxStyle">
                      {{ titleCase(item.name) }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <div class="p-modal-button-container">
            <a-button
              class="p-modal-cancel"
              size="large"
              @click="handleCancel"
            >
              Batal
            </a-button>
            <a-button
              type="primary"
              size="large"
              :disabled="btnCondition && btnTriggerClear"
              html-type="submit"
              class="p-modal-submit"
            >
              Simpan
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      formLayout: "vertical",
      value: 1,
      checkboxStyle: {
        display: "block",
        lineHeight: "24px",
        marginLeft: "0px",
      },
      paramCities: [],
      paramStatus: null,
      citiesAll: false,
      citiesIndeterminate: false,
      statusAll: false,
      statusIndeterminate: false,
      citiesCheckbox: [],
      statusCheckbox: [],
      btnCondition: true,
      btnTriggerClear: true,
    };
  },
  computed: {
    ...mapGetters("CityStore", ["getCities"]),
    ...mapGetters("ManageStoreStore", ["getListStoreCity", "getUIFlags"]),
    getListStoreCityComputed() {
      const listStoreCity = this.getListStoreCity.map((cityId) => this.getCities[
        this.getCities.findIndex((s) => s.id === cityId)
      ]);
      return listStoreCity;
    },
  },
  methods: {
    ...mapActions("ManageStoreStore", ["fetchListStoreCity"]),
    handleSubmit(e) {
      e.preventDefault();
      const params = { is_active: (this.paramStatus === null) ? null : (this.paramStatus === "true"), cities: this.paramCities };
      this.$emit("filter", params);
      this.btnTriggerClear = (this.paramStatus === null && this.paramCities.length === 0);
    },
    onChangeFilterStatus(checkedValues) {
      this.statusIndeterminate = !!checkedValues.length && checkedValues.length < 2;
      this.statusAll = checkedValues.length === 2;
      this.paramStatus = (checkedValues.length === 1) ? checkedValues[0] : null;
    },
    onCheckedAllOptionsStatus({ target }) {
      Object.assign(this, {
        statusCheckbox: target.checked ? target.value : null,
        statusIndeterminate: false,
        statusAll: target.checked,
      });
      this.paramStatus = null;
    },
    onChangeFilterCities(checkedValues) {
      this.citiesIndeterminate = !!checkedValues.length
        && checkedValues.length < this.getListStoreCity.length;
      this.citiesAll = checkedValues.length === this.getListStoreCity.length;
      this.paramCities = (checkedValues.length) ? checkedValues : [];
    },
    onCheckedAllOptionsCities({ target }) {
      Object.assign(this, {
        citiesCheckbox: target.checked ? target.value : [],
        citiesIndeterminate: false,
        citiesAll: target.checked,
      });
      this.paramCities = target.checked ? target.value : [];
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    fetchData() {
      this.isLoading = true;
      this.fetchListStoreCity()
        .then(() => {
          this.isFirst = false;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("error: ", error);
        });
    },
    titleCase(str) {
      const splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i += 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
  },
  updated() {
    this.btnCondition = (this.paramStatus === null && this.paramCities.length === 0);
  },
  mounted() {
    this.fetchData();
  },
});
</script>
