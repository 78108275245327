<template>
  <div>
    <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      centered
      :width="(visibleContent === 'Delete') ? 430 : (visibleContent === 'Filter') ? 520 : 800"
      :footer="null"
      :maskClosable="false"
      :closable="!isViewRolesInformation"
      :destroyOnClose="visibleContent !== 'Filter'"
      :style="{ borderRadius: '24px' }"
    >
      <template slot="title">
        <a-icon
          v-if="isViewRolesInformation"
          type="arrow-left"
          class="color-disabled"
          style="position: absolute; top: 24px; cursor: pointer;"
          @click="viewRolesInformation"
        />
        <div>
          <span
            class="p-modal-header-title"
          >{{ isViewRolesInformation ? "Informasi Apotek" : modalTitle }}</span>
        </div>
      </template>
      <template v-if="visibleContent === 'Filter'">
        <store-filter @filter="handleFilter" @handleCancel="handleCancel" />
      </template>
      <template v-else-if="visibleContent === 'Delete'">
        <delete-form
          :payload="{id: record.id}"
          :name="record.name"
          label="Cabang"
          :endPoint="deleteStore"
          @handleOk="handleOk"
          @handleCancel="handleCancel"
        />
      </template>
      <template v-else-if="visibleContent === ''">
        <div :style="'overflow: hidden;'">
          <store-form
            :id="recordId"
            :recordStep="recordStep"
            :client-id="recordClientId"
            @view-roles-information="viewRolesInformation"
            @handleOk="handleOk"
            @handleCancel="handleCancel"
          />
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import StoreForm from "@/components/store/StoreForm.vue";
import DeleteForm from "@/components/DeleteForm.vue";
import StoreFilter from "@/components/store/StoreFilter.vue";

export default Vue.extend({
  data() {
    return {
      visible: false,
      confirmLoading: false,
      isViewRolesInformation: false,
      record: {},
      recordId: "",
      recordStep: 0,
      recordClientId: "",
      visibleContent: "",
    };
  },
  props: {
    modalTitle: {
      type: String,
    },
  },
  components: {
    StoreForm,
    DeleteForm,
    StoreFilter,
  },
  methods: {
    ...mapActions("ManageStoreStore", ["deleteStore"]),
    showModal(record) {
      this.visible = true;
      this.visibleContent = "";
      this.recordId = "";
      this.recordClientId = "";
      this.recordStep = record.step;
      if (record.id) this.recordId = record.id;
    },
    showFilter() {
      this.visible = true;
      this.visibleContent = "Filter";
    },
    showVerification(data) {
      this.visible = true;
      this.visibleContent = "Verification";
      this.record = data;
    },
    showDelete(data) {
      this.visible = true;
      this.visibleContent = "Delete";
      this.record = data;
    },
    handleOk() {
      this.visible = false;
      this.$emit("fetchData");
    },
    handleCancel() {
      this.visible = false;
    },
    handleFilter(params) {
      this.visible = false;
      this.$emit("filter", params);
    },
    viewRolesInformation() {
      this.isViewRolesInformation = !this.isViewRolesInformation;
    },
  },
});
</script>
